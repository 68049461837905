import { render, staticRenderFns } from "./alt-text-validation-details.vue?vue&type=template&id=8785a7cc"
import script from "./alt-text-validation-details.vue?vue&type=script&lang=js"
export * from "./alt-text-validation-details.vue?vue&type=script&lang=js"
import style0 from "./alt-text-validation-details.vue?vue&type=style&index=0&id=8785a7cc&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports